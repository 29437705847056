@font-face {
  font-family: icomoon;
  src: url("icomoon.e1880bd2.eot");
  src: url("icomoon.e1880bd2.eot#iefix") format("embedded-opentype"), url("icomoon.6a6d209c.ttf") format("truetype"), url("icomoon.c0c1eed7.woff") format("woff"), url("icomoon.41718832.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-mobile:before {
  content: "";
}

.icon-laptop:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-document:before {
  content: "";
}

.icon-documents:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-notebook:before {
  content: "";
}

.icon-book-open:before {
  content: "";
}

.icon-browser:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-presentation:before {
  content: "";
}

.icon-picture:before {
  content: "";
}

.icon-pictures:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-printer:before {
  content: "";
}

.icon-toolbox:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-wallet:before {
  content: "";
}

.icon-gift:before {
  content: "";
}

.icon-bargraph:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-expand:before {
  content: "";
}

.icon-focus:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-adjustments:before {
  content: "";
}

.icon-ribbon:before {
  content: "";
}

.icon-hourglass:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-megaphone:before {
  content: "";
}

.icon-shield:before {
  content: "";
}

.icon-trophy:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-map:before {
  content: "";
}

.icon-puzzle:before {
  content: "";
}

.icon-basket:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-streetsign:before {
  content: "";
}

.icon-telescope:before {
  content: "";
}

.icon-gears:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-paperclip:before {
  content: "";
}

.icon-attachment:before {
  content: "";
}

.icon-pricetags:before {
  content: "";
}

.icon-lightbulb:before {
  content: "";
}

.icon-layers:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-tools:before {
  content: "";
}

.icon-tools-2:before {
  content: "";
}

.icon-scissors:before {
  content: "";
}

.icon-paintbrush:before {
  content: "";
}

.icon-magnifying-glass:before {
  content: "";
}

.icon-circle-compass:before {
  content: "";
}

.icon-linegraph:before {
  content: "";
}

.icon-mic:before {
  content: "";
}

.icon-strategy:before {
  content: "";
}

.icon-beaker:before {
  content: "";
}

.icon-caution:before {
  content: "";
}

.icon-recycle:before {
  content: "";
}

.icon-anchor:before {
  content: "";
}

.icon-profile-male:before {
  content: "";
}

.icon-profile-female:before {
  content: "";
}

.icon-bike:before {
  content: "";
}

.icon-wine:before {
  content: "";
}

.icon-hotairballoon:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-genius:before {
  content: "";
}

.icon-map-pin:before {
  content: "";
}

.icon-dial:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-target:before {
  content: "";
}

.icon-hazardous:before {
  content: "";
}

.icon-piechart:before {
  content: "";
}

.icon-speedometer:before {
  content: "";
}

.icon-global:before {
  content: "";
}

.icon-compass:before {
  content: "";
}

.icon-lifesaver:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-aperture:before {
  content: "";
}

.icon-quote:before {
  content: "";
}

.icon-scope:before {
  content: "";
}

.icon-alarmclock:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-happy:before {
  content: "";
}

.icon-sad:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-googleplus:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-tumblr:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-dribbble:before {
  content: "";
}

.icon-box2:before {
  content: "";
}

.icon-write:before {
  content: "";
}

.icon-clock2:before {
  content: "";
}

.icon-reply2:before {
  content: "";
}

.icon-reply-all2:before {
  content: "";
}

.icon-forward2:before {
  content: "";
}

.icon-flag2:before {
  content: "";
}

.icon-search2:before {
  content: "";
}

.icon-trash2:before {
  content: "";
}

.icon-envelope2:before {
  content: "";
}

.icon-bubble:before {
  content: "";
}

.icon-bubbles:before {
  content: "";
}

.icon-user2:before {
  content: "";
}

.icon-users2:before {
  content: "";
}

.icon-cloud2:before {
  content: "";
}

.icon-download2:before {
  content: "";
}

.icon-upload2:before {
  content: "";
}

.icon-rain:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-moon2:before {
  content: "";
}

.icon-bell2:before {
  content: "";
}

.icon-folder2:before {
  content: "";
}

.icon-pin2:before {
  content: "";
}

.icon-sound2:before {
  content: "";
}

.icon-microphone:before {
  content: "";
}

.icon-camera2:before {
  content: "";
}

.icon-image2:before {
  content: "";
}

.icon-cog2:before {
  content: "";
}

.icon-calendar2:before {
  content: "";
}

.icon-book2:before {
  content: "";
}

.icon-map-marker:before {
  content: "";
}

.icon-store:before {
  content: "";
}

.icon-support:before {
  content: "";
}

.icon-tag2:before {
  content: "";
}

.icon-heart2:before {
  content: "";
}

.icon-video-camera:before {
  content: "";
}

.icon-trophy2:before {
  content: "";
}

.icon-cart:before {
  content: "";
}

.icon-eye2:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-chart:before {
  content: "";
}

.icon-target2:before {
  content: "";
}

.icon-printer2:before {
  content: "";
}

.icon-location2:before {
  content: "";
}

.icon-bookmark2:before {
  content: "";
}

.icon-monitor:before {
  content: "";
}

.icon-cross2:before {
  content: "";
}

.icon-plus2:before {
  content: "";
}

.icon-left:before {
  content: "";
}

.icon-up:before {
  content: "";
}

.icon-browser2:before {
  content: "";
}

.icon-windows:before {
  content: "";
}

.icon-switch2:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-fast-forward:before {
  content: "";
}

.icon-next:before {
  content: "";
}

.icon-refresh2:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-home2:before {
  content: "";
}

.icon-add-to-list:before {
  content: "";
}

.icon-classic-computer:before {
  content: "";
}

.icon-controller-fast-backward:before {
  content: "";
}

.icon-creative-commons-attribution:before {
  content: "";
}

.icon-creative-commons-noderivs:before {
  content: "";
}

.icon-creative-commons-noncommercial-eu:before {
  content: "";
}

.icon-creative-commons-noncommercial-us:before {
  content: "";
}

.icon-creative-commons-public-domain:before {
  content: "";
}

.icon-creative-commons-remix:before {
  content: "";
}

.icon-creative-commons-share:before {
  content: "";
}

.icon-creative-commons-sharealike:before {
  content: "";
}

.icon-creative-commons:before {
  content: "";
}

.icon-document-landscape:before {
  content: "";
}

.icon-remove-user:before {
  content: "";
}

.icon-warning:before {
  content: "";
}

.icon-arrow-bold-down:before {
  content: "";
}

.icon-arrow-bold-left:before {
  content: "";
}

.icon-arrow-bold-right:before {
  content: "";
}

.icon-arrow-bold-up:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-long-down:before {
  content: "";
}

.icon-arrow-long-left:before {
  content: "";
}

.icon-arrow-long-right:before {
  content: "";
}

.icon-arrow-long-up:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-arrow-with-circle-down:before {
  content: "";
}

.icon-arrow-with-circle-left:before {
  content: "";
}

.icon-arrow-with-circle-right:before {
  content: "";
}

.icon-arrow-with-circle-up:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-bookmarks:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-small-down:before {
  content: "";
}

.icon-chevron-small-left:before {
  content: "";
}

.icon-chevron-small-right:before {
  content: "";
}

.icon-chevron-small-up:before {
  content: "";
}

.icon-chevron-thin-down:before {
  content: "";
}

.icon-chevron-thin-left:before {
  content: "";
}

.icon-chevron-thin-right:before {
  content: "";
}

.icon-chevron-thin-up:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevron-with-circle-down:before {
  content: "";
}

.icon-chevron-with-circle-left:before {
  content: "";
}

.icon-chevron-with-circle-right:before {
  content: "";
}

.icon-chevron-with-circle-up:before {
  content: "";
}

.icon-cloud3:before {
  content: "";
}

.icon-controller-fast-forward:before {
  content: "";
}

.icon-controller-jump-to-start:before {
  content: "";
}

.icon-controller-next:before {
  content: "";
}

.icon-controller-paus:before {
  content: "";
}

.icon-controller-play:before {
  content: "";
}

.icon-controller-record:before {
  content: "";
}

.icon-controller-stop:before {
  content: "";
}

.icon-controller-volume:before {
  content: "";
}

.icon-dot-single:before {
  content: "";
}

.icon-dots-three-horizontal:before {
  content: "";
}

.icon-dots-three-vertical:before {
  content: "";
}

.icon-dots-two-horizontal:before {
  content: "";
}

.icon-dots-two-vertical:before {
  content: "";
}

.icon-download3:before {
  content: "";
}

.icon-emoji-flirt:before {
  content: "";
}

.icon-flow-branch:before {
  content: "";
}

.icon-flow-cascade:before {
  content: "";
}

.icon-flow-line:before {
  content: "";
}

.icon-flow-parallel:before {
  content: "";
}

.icon-flow-tree:before {
  content: "";
}

.icon-install:before {
  content: "";
}

.icon-layers2:before {
  content: "";
}

.icon-open-book:before {
  content: "";
}

.icon-resize-100:before {
  content: "";
}

.icon-resize-full-screen:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-select-arrows:before {
  content: "";
}

.icon-sound-mute:before {
  content: "";
}

.icon-sound:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-triangle-down:before {
  content: "";
}

.icon-triangle-left:before {
  content: "";
}

.icon-triangle-right:before {
  content: "";
}

.icon-triangle-up:before {
  content: "";
}

.icon-uninstall:before {
  content: "";
}

.icon-upload-to-cloud:before {
  content: "";
}

.icon-upload3:before {
  content: "";
}

.icon-add-user:before {
  content: "";
}

.icon-address:before {
  content: "";
}

.icon-adjust:before {
  content: "";
}

.icon-air:before {
  content: "";
}

.icon-aircraft-landing:before {
  content: "";
}

.icon-aircraft-take-off:before {
  content: "";
}

.icon-aircraft:before {
  content: "";
}

.icon-align-bottom:before {
  content: "";
}

.icon-align-horizontal-middle:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-align-top:before {
  content: "";
}

.icon-align-vertical-middle:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-area-graph:before {
  content: "";
}

.icon-attachment2:before {
  content: "";
}

.icon-awareness-ribbon:before {
  content: "";
}

.icon-back-in-time:before {
  content: "";
}

.icon-back:before {
  content: "";
}

.icon-bar-graph:before {
  content: "";
}

.icon-battery:before {
  content: "";
}

.icon-beamed-note:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-blackboard:before {
  content: "";
}

.icon-block:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-bowl:before {
  content: "";
}

.icon-box:before {
  content: "";
}

.icon-briefcase2:before {
  content: "";
}

.icon-browser3:before {
  content: "";
}

.icon-brush:before {
  content: "";
}

.icon-bucket:before {
  content: "";
}

.icon-cake:before {
  content: "";
}

.icon-calculator:before {
  content: "";
}

.icon-calendar3:before {
  content: "";
}

.icon-camera3:before {
  content: "";
}

.icon-ccw:before {
  content: "";
}

.icon-chat2:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-circle-with-cross:before {
  content: "";
}

.icon-circle-with-minus:before {
  content: "";
}

.icon-circle-with-plus:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-circular-graph:before {
  content: "";
}

.icon-clapperboard:before {
  content: "";
}

.icon-clipboard2:before {
  content: "";
}

.icon-clock3:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-colours:before {
  content: "";
}

.icon-compass2:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-credit:before {
  content: "";
}

.icon-cross:before {
  content: "";
}

.icon-cup:before {
  content: "";
}

.icon-cw:before {
  content: "";
}

.icon-cycle:before {
  content: "";
}

.icon-database:before {
  content: "";
}

.icon-dial-pad:before {
  content: "";
}

.icon-direction:before {
  content: "";
}

.icon-document2:before {
  content: "";
}

.icon-documents2:before {
  content: "";
}

.icon-drink:before {
  content: "";
}

.icon-drive:before {
  content: "";
}

.icon-drop:before {
  content: "";
}

.icon-edit2:before {
  content: "";
}

.icon-email:before {
  content: "";
}

.icon-emoji-happy:before {
  content: "";
}

.icon-emoji-neutral:before {
  content: "";
}

.icon-emoji-sad:before {
  content: "";
}

.icon-erase:before {
  content: "";
}

.icon-eraser:before {
  content: "";
}

.icon-export:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-feather:before {
  content: "";
}

.icon-flag3:before {
  content: "";
}

.icon-flash:before {
  content: "";
}

.icon-flashlight:before {
  content: "";
}

.icon-flat-brush:before {
  content: "";
}

.icon-folder-images:before {
  content: "";
}

.icon-folder-music:before {
  content: "";
}

.icon-folder-video:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-forward:before {
  content: "";
}

.icon-funnel:before {
  content: "";
}

.icon-game-controller:before {
  content: "";
}

.icon-gauge:before {
  content: "";
}

.icon-globe2:before {
  content: "";
}

.icon-graduation-cap:before {
  content: "";
}

.icon-grid2:before {
  content: "";
}

.icon-hair-cross:before {
  content: "";
}

.icon-hand:before {
  content: "";
}

.icon-heart-outlined:before {
  content: "";
}

.icon-heart3:before {
  content: "";
}

.icon-help-with-circle:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-hour-glass:before {
  content: "";
}

.icon-image-inverted:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-images:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-infinity:before {
  content: "";
}

.icon-info-with-circle:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-key2:before {
  content: "";
}

.icon-keyboard:before {
  content: "";
}

.icon-lab-flask:before {
  content: "";
}

.icon-landline:before {
  content: "";
}

.icon-language:before {
  content: "";
}

.icon-laptop2:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-level-down:before {
  content: "";
}

.icon-level-up:before {
  content: "";
}

.icon-lifebuoy:before {
  content: "";
}

.icon-light-bulb:before {
  content: "";
}

.icon-light-down:before {
  content: "";
}

.icon-light-up:before {
  content: "";
}

.icon-line-graph:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-location-pin:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-lock-open:before {
  content: "";
}

.icon-lock2:before {
  content: "";
}

.icon-log-out:before {
  content: "";
}

.icon-login:before {
  content: "";
}

.icon-loop:before {
  content: "";
}

.icon-magnet:before {
  content: "";
}

.icon-magnifying-glass2:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-man:before {
  content: "";
}

.icon-map2:before {
  content: "";
}

.icon-mask:before {
  content: "";
}

.icon-medal:before {
  content: "";
}

.icon-megaphone2:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-message:before {
  content: "";
}

.icon-mic2:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-mobile2:before {
  content: "";
}

.icon-modern-mic:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-mouse:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-network:before {
  content: "";
}

.icon-new-message:before {
  content: "";
}

.icon-new:before {
  content: "";
}

.icon-news:before {
  content: "";
}

.icon-note:before {
  content: "";
}

.icon-notification:before {
  content: "";
}

.icon-old-mobile:before {
  content: "";
}

.icon-old-phone:before {
  content: "";
}

.icon-palette:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-pencil2:before {
  content: "";
}

.icon-phone2:before {
  content: "";
}

.icon-pie-chart:before {
  content: "";
}

.icon-pin:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-popup:before {
  content: "";
}

.icon-power-plug:before {
  content: "";
}

.icon-price-ribbon:before {
  content: "";
}

.icon-price-tag:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-progress-empty:before {
  content: "";
}

.icon-progress-full:before {
  content: "";
}

.icon-progress-one:before {
  content: "";
}

.icon-progress-two:before {
  content: "";
}

.icon-publish:before {
  content: "";
}

.icon-quote2:before {
  content: "";
}

.icon-radio:before {
  content: "";
}

.icon-reply-all:before {
  content: "";
}

.icon-reply:before {
  content: "";
}

.icon-retweet:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-round-brush:before {
  content: "";
}

.icon-rss2:before {
  content: "";
}

.icon-ruler:before {
  content: "";
}

.icon-scissors2:before {
  content: "";
}

.icon-share-alternitive:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-shareable:before {
  content: "";
}

.icon-shield2:before {
  content: "";
}

.icon-shop:before {
  content: "";
}

.icon-shopping-bag:before {
  content: "";
}

.icon-shopping-basket:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-shuffle:before {
  content: "";
}

.icon-signal:before {
  content: "";
}

.icon-sound-mix:before {
  content: "";
}

.icon-sports-club:before {
  content: "";
}

.icon-spreadsheet:before {
  content: "";
}

.icon-squared-cross:before {
  content: "";
}

.icon-squared-minus:before {
  content: "";
}

.icon-squared-plus:before {
  content: "";
}

.icon-star-outlined:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-stopwatch:before {
  content: "";
}

.icon-suitcase:before {
  content: "";
}

.icon-swap:before {
  content: "";
}

.icon-sweden:before {
  content: "";
}

.icon-switch:before {
  content: "";
}

.icon-tablet2:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-text-document-inverted:before {
  content: "";
}

.icon-text-document:before {
  content: "";
}

.icon-text:before {
  content: "";
}

.icon-thermometer:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-thunder-cloud:before {
  content: "";
}

.icon-ticket:before {
  content: "";
}

.icon-time-slot:before {
  content: "";
}

.icon-tools2:before {
  content: "";
}

.icon-traffic-cone:before {
  content: "";
}

.icon-tree:before {
  content: "";
}

.icon-trophy3:before {
  content: "";
}

.icon-tv:before {
  content: "";
}

.icon-typing:before {
  content: "";
}

.icon-unread:before {
  content: "";
}

.icon-untag:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-v-card:before {
  content: "";
}

.icon-video2:before {
  content: "";
}

.icon-vinyl:before {
  content: "";
}

.icon-voicemail:before {
  content: "";
}

.icon-wallet2:before {
  content: "";
}

.icon-water:before {
  content: "";
}

.icon-500px-with-circle:before {
  content: "";
}

.icon-500px:before {
  content: "";
}

.icon-basecamp:before {
  content: "";
}

.icon-behance:before {
  content: "";
}

.icon-creative-cloud:before {
  content: "";
}

.icon-dropbox:before {
  content: "";
}

.icon-evernote:before {
  content: "";
}

.icon-flattr:before {
  content: "";
}

.icon-foursquare:before {
  content: "";
}

.icon-google-drive:before {
  content: "";
}

.icon-google-hangouts:before {
  content: "";
}

.icon-grooveshark:before {
  content: "";
}

.icon-icloud:before {
  content: "";
}

.icon-mixi:before {
  content: "";
}

.icon-onedrive:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-picasa:before {
  content: "";
}

.icon-qq:before {
  content: "";
}

.icon-rdio-with-circle:before {
  content: "";
}

.icon-renren:before {
  content: "";
}

.icon-scribd:before {
  content: "";
}

.icon-sina-weibo:before {
  content: "";
}

.icon-skype-with-circle:before {
  content: "";
}

.icon-skype:before {
  content: "";
}

.icon-slideshare:before {
  content: "";
}

.icon-smashing:before {
  content: "";
}

.icon-soundcloud:before {
  content: "";
}

.icon-spotify-with-circle:before {
  content: "";
}

.icon-spotify:before {
  content: "";
}

.icon-swarm:before {
  content: "";
}

.icon-vine-with-circle:before {
  content: "";
}

.icon-vine:before {
  content: "";
}

.icon-vk-alternitive:before {
  content: "";
}

.icon-vk-with-circle:before {
  content: "";
}

.icon-vk:before {
  content: "";
}

.icon-xing-with-circle:before {
  content: "";
}

.icon-xing:before {
  content: "";
}

.icon-yelp:before {
  content: "";
}

.icon-dribbble-with-circle:before {
  content: "";
}

.icon-dribbble2:before {
  content: "";
}

.icon-facebook-with-circle:before {
  content: "";
}

.icon-facebook2:before {
  content: "";
}

.icon-flickr-with-circle:before {
  content: "";
}

.icon-flickr:before {
  content: "";
}

.icon-github-with-circle:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-google-with-circle:before {
  content: "";
}

.icon-google:before {
  content: "";
}

.icon-instagram-with-circle:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-lastfm-with-circle:before {
  content: "";
}

.icon-lastfm:before {
  content: "";
}

.icon-linkedin-with-circle:before {
  content: "";
}

.icon-linkedin2:before {
  content: "";
}

.icon-pinterest-with-circle:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-rdio:before {
  content: "";
}

.icon-stumbleupon-with-circle:before {
  content: "";
}

.icon-stumbleupon:before {
  content: "";
}

.icon-tumblr-with-circle:before {
  content: "";
}

.icon-tumblr2:before {
  content: "";
}

.icon-twitter-with-circle:before {
  content: "";
}

.icon-twitter2:before {
  content: "";
}

.icon-vimeo-with-circle:before {
  content: "";
}

.icon-vimeo:before {
  content: "";
}

.icon-youtube-with-circle:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-home3:before {
  content: "";
}

.icon-home22:before {
  content: "";
}

.icon-home32:before {
  content: "";
}

.icon-office:before {
  content: "";
}

.icon-newspaper2:before {
  content: "";
}

.icon-pencil3:before {
  content: "";
}

.icon-pencil22:before {
  content: "";
}

.icon-quill:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-blog:before {
  content: "";
}

.icon-eyedropper:before {
  content: "";
}

.icon-droplet:before {
  content: "";
}

.icon-paint-format:before {
  content: "";
}

.icon-image3:before {
  content: "";
}

.icon-images2:before {
  content: "";
}

.icon-camera4:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-music2:before {
  content: "";
}

.icon-play2:before {
  content: "";
}

.icon-film2:before {
  content: "";
}

.icon-video-camera2:before {
  content: "";
}

.icon-dice:before {
  content: "";
}

.icon-pacman:before {
  content: "";
}

.icon-spades:before {
  content: "";
}

.icon-clubs:before {
  content: "";
}

.icon-diamonds:before {
  content: "";
}

.icon-bullhorn:before {
  content: "";
}

.icon-connection:before {
  content: "";
}

.icon-podcast:before {
  content: "";
}

.icon-feed:before {
  content: "";
}

.icon-mic3:before {
  content: "";
}

.icon-book3:before {
  content: "";
}

.icon-books:before {
  content: "";
}

.icon-library:before {
  content: "";
}

.icon-file-text:before {
  content: "";
}

.icon-profile:before {
  content: "";
}

.icon-file-empty:before {
  content: "";
}

.icon-files-empty:before {
  content: "";
}

.icon-file-text2:before {
  content: "";
}

.icon-file-picture:before {
  content: "";
}

.icon-file-music:before {
  content: "";
}

.icon-file-play:before {
  content: "";
}

.icon-file-video:before {
  content: "";
}

.icon-file-zip:before {
  content: "";
}

.icon-copy2:before {
  content: "";
}

.icon-paste:before {
  content: "";
}

.icon-stack:before {
  content: "";
}

.icon-folder3:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-folder-plus:before {
  content: "";
}

.icon-folder-minus:before {
  content: "";
}

.icon-folder-download:before {
  content: "";
}

.icon-folder-upload:before {
  content: "";
}

.icon-price-tag2:before {
  content: "";
}

.icon-price-tags:before {
  content: "";
}

.icon-barcode:before {
  content: "";
}

.icon-qrcode:before {
  content: "";
}

.icon-ticket2:before {
  content: "";
}

.icon-cart2:before {
  content: "";
}

.icon-coin-dollar:before {
  content: "";
}

.icon-coin-euro:before {
  content: "";
}

.icon-coin-pound:before {
  content: "";
}

.icon-coin-yen:before {
  content: "";
}

.icon-credit-card2:before {
  content: "";
}

.icon-calculator2:before {
  content: "";
}

.icon-lifebuoy2:before {
  content: "";
}

.icon-phone3:before {
  content: "";
}

.icon-phone-hang-up:before {
  content: "";
}

.icon-address-book:before {
  content: "";
}

.icon-envelop:before {
  content: "";
}

.icon-pushpin:before {
  content: "";
}

.icon-location3:before {
  content: "";
}

.icon-location22:before {
  content: "";
}

.icon-compass3:before {
  content: "";
}

.icon-compass22:before {
  content: "";
}

.icon-map3:before {
  content: "";
}

.icon-map22:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-clock4:before {
  content: "";
}

.icon-clock22:before {
  content: "";
}

.icon-alarm:before {
  content: "";
}

.icon-bell3:before {
  content: "";
}

.icon-stopwatch2:before {
  content: "";
}

.icon-calendar4:before {
  content: "";
}

.icon-printer3:before {
  content: "";
}

.icon-keyboard2:before {
  content: "";
}

.icon-display:before {
  content: "";
}

.icon-laptop3:before {
  content: "";
}

.icon-mobile3:before {
  content: "";
}

.icon-mobile22:before {
  content: "";
}

.icon-tablet3:before {
  content: "";
}

.icon-tv2:before {
  content: "";
}

.icon-drawer:before {
  content: "";
}

.icon-drawer2:before {
  content: "";
}

.icon-box-add:before {
  content: "";
}

.icon-box-remove:before {
  content: "";
}

.icon-download4:before {
  content: "";
}

.icon-upload4:before {
  content: "";
}

.icon-floppy-disk:before {
  content: "";
}

.icon-drive2:before {
  content: "";
}

.icon-database2:before {
  content: "";
}

.icon-undo:before {
  content: "";
}

.icon-redo:before {
  content: "";
}

.icon-undo2:before {
  content: "";
}

.icon-redo2:before {
  content: "";
}

.icon-forward3:before {
  content: "";
}

.icon-reply3:before {
  content: "";
}

.icon-bubble2:before {
  content: "";
}

.icon-bubbles2:before {
  content: "";
}

.icon-bubbles22:before {
  content: "";
}

.icon-bubble22:before {
  content: "";
}

.icon-bubbles3:before {
  content: "";
}

.icon-bubbles4:before {
  content: "";
}

.icon-user3:before {
  content: "";
}

.icon-users3:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-minus:before {
  content: "";
}

.icon-user-check:before {
  content: "";
}

.icon-user-tie:before {
  content: "";
}

.icon-quotes-left:before {
  content: "";
}

.icon-quotes-right:before {
  content: "";
}

.icon-hour-glass2:before {
  content: "";
}

.icon-spinner:before {
  content: "";
}

.icon-spinner2:before {
  content: "";
}

.icon-spinner3:before {
  content: "";
}

.icon-spinner4:before {
  content: "";
}

.icon-spinner5:before {
  content: "";
}

.icon-spinner6:before {
  content: "";
}

.icon-spinner7:before {
  content: "";
}

.icon-spinner8:before {
  content: "";
}

.icon-spinner9:before {
  content: "";
}

.icon-spinner10:before {
  content: "";
}

.icon-spinner11:before {
  content: "";
}

.icon-binoculars:before {
  content: "";
}

.icon-search3:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.icon-enlarge:before {
  content: "";
}

.icon-shrink:before {
  content: "";
}

.icon-enlarge2:before {
  content: "";
}

.icon-shrink2:before {
  content: "";
}

.icon-key3:before {
  content: "";
}

.icon-key22:before {
  content: "";
}

.icon-lock3:before {
  content: "";
}

.icon-unlocked:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-equalizer:before {
  content: "";
}

.icon-equalizer2:before {
  content: "";
}

.icon-cog3:before {
  content: "";
}

.icon-cogs:before {
  content: "";
}

.icon-hammer:before {
  content: "";
}

.icon-magic-wand:before {
  content: "";
}

.icon-aid-kit:before {
  content: "";
}

.icon-bug:before {
  content: "";
}

.icon-pie-chart2:before {
  content: "";
}

.icon-stats-dots:before {
  content: "";
}

.icon-stats-bars:before {
  content: "";
}

.icon-stats-bars2:before {
  content: "";
}

.icon-trophy4:before {
  content: "";
}

.icon-gift2:before {
  content: "";
}

.icon-glass:before {
  content: "";
}

.icon-glass2:before {
  content: "";
}

.icon-mug:before {
  content: "";
}

.icon-spoon-knife:before {
  content: "";
}

.icon-leaf2:before {
  content: "";
}

.icon-rocket2:before {
  content: "";
}

.icon-meter:before {
  content: "";
}

.icon-meter2:before {
  content: "";
}

.icon-hammer2:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-lab:before {
  content: "";
}

.icon-magnet2:before {
  content: "";
}

.icon-bin:before {
  content: "";
}

.icon-bin2:before {
  content: "";
}

.icon-briefcase3:before {
  content: "";
}

.icon-airplane:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-road:before {
  content: "";
}

.icon-accessibility:before {
  content: "";
}

.icon-target3:before {
  content: "";
}

.icon-shield3:before {
  content: "";
}

.icon-power:before {
  content: "";
}

.icon-switch3:before {
  content: "";
}

.icon-power-cord:before {
  content: "";
}

.icon-clipboard3:before {
  content: "";
}

.icon-list-numbered:before {
  content: "";
}

.icon-list2:before {
  content: "";
}

.icon-list22:before {
  content: "";
}

.icon-tree2:before {
  content: "";
}

.icon-menu2:before {
  content: "";
}

.icon-menu22:before {
  content: "";
}

.icon-menu3:before {
  content: "";
}

.icon-menu4:before {
  content: "";
}

.icon-cloud4:before {
  content: "";
}

.icon-cloud-download:before {
  content: "";
}

.icon-cloud-upload:before {
  content: "";
}

.icon-cloud-check:before {
  content: "";
}

.icon-download22:before {
  content: "";
}

.icon-upload22:before {
  content: "";
}

.icon-download32:before {
  content: "";
}

.icon-upload32:before {
  content: "";
}

.icon-sphere:before {
  content: "";
}

.icon-earth:before {
  content: "";
}

.icon-link2:before {
  content: "";
}

.icon-flag4:before {
  content: "";
}

.icon-attachment3:before {
  content: "";
}

.icon-eye3:before {
  content: "";
}

.icon-eye-plus:before {
  content: "";
}

.icon-eye-minus:before {
  content: "";
}

.icon-eye-blocked:before {
  content: "";
}

.icon-bookmark3:before {
  content: "";
}

.icon-bookmarks2:before {
  content: "";
}

.icon-sun2:before {
  content: "";
}

.icon-contrast:before {
  content: "";
}

.icon-brightness-contrast:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-star-full:before {
  content: "";
}

.icon-heart4:before {
  content: "";
}

.icon-heart-broken:before {
  content: "";
}

.icon-man2:before {
  content: "";
}

.icon-woman:before {
  content: "";
}

.icon-man-woman:before {
  content: "";
}

.icon-happy2:before {
  content: "";
}

.icon-happy22:before {
  content: "";
}

.icon-smile:before {
  content: "";
}

.icon-smile2:before {
  content: "";
}

.icon-tongue:before {
  content: "";
}

.icon-tongue2:before {
  content: "";
}

.icon-sad2:before {
  content: "";
}

.icon-sad22:before {
  content: "";
}

.icon-wink:before {
  content: "";
}

.icon-wink2:before {
  content: "";
}

.icon-grin:before {
  content: "";
}

.icon-grin2:before {
  content: "";
}

.icon-cool:before {
  content: "";
}

.icon-cool2:before {
  content: "";
}

.icon-angry:before {
  content: "";
}

.icon-angry2:before {
  content: "";
}

.icon-evil:before {
  content: "";
}

.icon-evil2:before {
  content: "";
}

.icon-shocked:before {
  content: "";
}

.icon-shocked2:before {
  content: "";
}

.icon-baffled:before {
  content: "";
}

.icon-baffled2:before {
  content: "";
}

.icon-confused:before {
  content: "";
}

.icon-confused2:before {
  content: "";
}

.icon-neutral:before {
  content: "";
}

.icon-neutral2:before {
  content: "";
}

.icon-hipster:before {
  content: "";
}

.icon-hipster2:before {
  content: "";
}

.icon-wondering:before {
  content: "";
}

.icon-wondering2:before {
  content: "";
}

.icon-sleepy:before {
  content: "";
}

.icon-sleepy2:before {
  content: "";
}

.icon-frustrated:before {
  content: "";
}

.icon-frustrated2:before {
  content: "";
}

.icon-crying:before {
  content: "";
}

.icon-crying2:before {
  content: "";
}

.icon-point-up:before {
  content: "";
}

.icon-point-right:before {
  content: "";
}

.icon-point-down:before {
  content: "";
}

.icon-point-left:before {
  content: "";
}

.icon-warning2:before {
  content: "";
}

.icon-notification2:before {
  content: "";
}

.icon-question:before {
  content: "";
}

.icon-plus3:before {
  content: "";
}

.icon-minus2:before {
  content: "";
}

.icon-info2:before {
  content: "";
}

.icon-cancel-circle:before {
  content: "";
}

.icon-blocked:before {
  content: "";
}

.icon-cross3:before {
  content: "";
}

.icon-checkmark:before {
  content: "";
}

.icon-checkmark2:before {
  content: "";
}

.icon-spell-check:before {
  content: "";
}

.icon-enter:before {
  content: "";
}

.icon-exit:before {
  content: "";
}

.icon-play22:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-previous:before {
  content: "";
}

.icon-next2:before {
  content: "";
}

.icon-backward:before {
  content: "";
}

.icon-forward22:before {
  content: "";
}

.icon-play3:before {
  content: "";
}

.icon-pause2:before {
  content: "";
}

.icon-stop2:before {
  content: "";
}

.icon-backward2:before {
  content: "";
}

.icon-forward32:before {
  content: "";
}

.icon-first:before {
  content: "";
}

.icon-last:before {
  content: "";
}

.icon-previous2:before {
  content: "";
}

.icon-next22:before {
  content: "";
}

.icon-eject:before {
  content: "";
}

.icon-volume-high:before {
  content: "";
}

.icon-volume-medium:before {
  content: "";
}

.icon-volume-low:before {
  content: "";
}

.icon-volume-mute:before {
  content: "";
}

.icon-volume-mute2:before {
  content: "";
}

.icon-volume-increase:before {
  content: "";
}

.icon-volume-decrease:before {
  content: "";
}

.icon-loop2:before {
  content: "";
}

.icon-loop22:before {
  content: "";
}

.icon-infinite:before {
  content: "";
}

.icon-shuffle2:before {
  content: "";
}

.icon-arrow-up-left:before {
  content: "";
}

.icon-arrow-up2:before {
  content: "";
}

.icon-arrow-up-right:before {
  content: "";
}

.icon-arrow-right2:before {
  content: "";
}

.icon-arrow-down-right:before {
  content: "";
}

.icon-arrow-down2:before {
  content: "";
}

.icon-arrow-down-left:before {
  content: "";
}

.icon-arrow-left2:before {
  content: "";
}

.icon-arrow-up-left2:before {
  content: "";
}

.icon-arrow-up22:before {
  content: "";
}

.icon-arrow-up-right2:before {
  content: "";
}

.icon-arrow-right22:before {
  content: "";
}

.icon-arrow-down-right2:before {
  content: "";
}

.icon-arrow-down22:before {
  content: "";
}

.icon-arrow-down-left2:before {
  content: "";
}

.icon-arrow-left22:before {
  content: "";
}

.icon-circle-up:before {
  content: "";
}

.icon-circle-right:before {
  content: "";
}

.icon-circle-down:before {
  content: "";
}

.icon-circle-left:before {
  content: "";
}

.icon-tab:before {
  content: "";
}

.icon-move-up:before {
  content: "";
}

.icon-move-down:before {
  content: "";
}

.icon-sort-alpha-asc:before {
  content: "";
}

.icon-sort-alpha-desc:before {
  content: "";
}

.icon-sort-numeric-asc:before {
  content: "";
}

.icon-sort-numberic-desc:before {
  content: "";
}

.icon-sort-amount-asc:before {
  content: "";
}

.icon-sort-amount-desc:before {
  content: "";
}

.icon-command:before {
  content: "";
}

.icon-shift:before {
  content: "";
}

.icon-ctrl:before {
  content: "";
}

.icon-opt:before {
  content: "";
}

.icon-checkbox-checked:before {
  content: "";
}

.icon-checkbox-unchecked:before {
  content: "";
}

.icon-radio-checked:before {
  content: "";
}

.icon-radio-checked2:before {
  content: "";
}

.icon-radio-unchecked:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-make-group:before {
  content: "";
}

.icon-ungroup:before {
  content: "";
}

.icon-scissors3:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-font:before {
  content: "";
}

.icon-ligature:before {
  content: "";
}

.icon-ligature2:before {
  content: "";
}

.icon-text-height:before {
  content: "";
}

.icon-text-width:before {
  content: "";
}

.icon-font-size:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-strikethrough:before {
  content: "";
}

.icon-omega:before {
  content: "";
}

.icon-sigma:before {
  content: "";
}

.icon-page-break:before {
  content: "";
}

.icon-superscript:before {
  content: "";
}

.icon-subscript:before {
  content: "";
}

.icon-superscript2:before {
  content: "";
}

.icon-subscript2:before {
  content: "";
}

.icon-text-color:before {
  content: "";
}

.icon-pagebreak:before {
  content: "";
}

.icon-clear-formatting:before {
  content: "";
}

.icon-table:before {
  content: "";
}

.icon-table2:before {
  content: "";
}

.icon-insert-template:before {
  content: "";
}

.icon-pilcrow:before {
  content: "";
}

.icon-ltr:before {
  content: "";
}

.icon-rtl:before {
  content: "";
}

.icon-section:before {
  content: "";
}

.icon-paragraph-left:before {
  content: "";
}

.icon-paragraph-center:before {
  content: "";
}

.icon-paragraph-right:before {
  content: "";
}

.icon-paragraph-justify:before {
  content: "";
}

.icon-indent-increase:before {
  content: "";
}

.icon-indent-decrease:before {
  content: "";
}

.icon-share2:before {
  content: "";
}

.icon-new-tab:before {
  content: "";
}

.icon-embed:before {
  content: "";
}

.icon-embed2:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-share22:before {
  content: "";
}

.icon-mail2:before {
  content: "";
}

.icon-mail22:before {
  content: "";
}

.icon-mail3:before {
  content: "";
}

.icon-mail4:before {
  content: "";
}

.icon-amazon:before {
  content: "";
}

.icon-google2:before {
  content: "";
}

.icon-google22:before {
  content: "";
}

.icon-google3:before {
  content: "";
}

.icon-google-plus:before {
  content: "";
}

.icon-google-plus2:before {
  content: "";
}

.icon-google-plus3:before {
  content: "";
}

.icon-hangouts:before {
  content: "";
}

.icon-google-drive2:before {
  content: "";
}

.icon-facebook3:before {
  content: "";
}

.icon-facebook22:before {
  content: "";
}

.icon-instagram2:before {
  content: "";
}

.icon-whatsapp:before {
  content: "";
}

.icon-spotify2:before {
  content: "";
}

.icon-telegram:before {
  content: "";
}

.icon-twitter3:before {
  content: "";
}

.icon-vine2:before {
  content: "";
}

.icon-vk2:before {
  content: "";
}

.icon-renren2:before {
  content: "";
}

.icon-sina-weibo2:before {
  content: "";
}

.icon-rss3:before {
  content: "";
}

.icon-rss22:before {
  content: "";
}

.icon-youtube2:before {
  content: "";
}

.icon-youtube22:before {
  content: "";
}

.icon-twitch:before {
  content: "";
}

.icon-vimeo2:before {
  content: "";
}

.icon-vimeo22:before {
  content: "";
}

.icon-lanyrd:before {
  content: "";
}

.icon-flickr2:before {
  content: "";
}

.icon-flickr22:before {
  content: "";
}

.icon-flickr3:before {
  content: "";
}

.icon-flickr4:before {
  content: "";
}

.icon-dribbble3:before {
  content: "";
}

.icon-behance2:before {
  content: "";
}

.icon-behance22:before {
  content: "";
}

.icon-deviantart:before {
  content: "";
}

.icon-500px2:before {
  content: "";
}

.icon-steam:before {
  content: "";
}

.icon-steam2:before {
  content: "";
}

.icon-dropbox2:before {
  content: "";
}

.icon-onedrive2:before {
  content: "";
}

.icon-github2:before {
  content: "";
}

.icon-npm:before {
  content: "";
}

.icon-basecamp2:before {
  content: "";
}

.icon-trello:before {
  content: "";
}

.icon-wordpress:before {
  content: "";
}

.icon-joomla:before {
  content: "";
}

.icon-ello:before {
  content: "";
}

.icon-blogger:before {
  content: "";
}

.icon-blogger2:before {
  content: "";
}

.icon-tumblr3:before {
  content: "";
}

.icon-tumblr22:before {
  content: "";
}

.icon-yahoo:before {
  content: "";
}

.icon-yahoo2:before {
  content: "";
}

.icon-tux:before {
  content: "";
}

.icon-appleinc:before {
  content: "";
}

.icon-finder:before {
  content: "";
}

.icon-android:before {
  content: "";
}

.icon-windows2:before {
  content: "";
}

.icon-windows8:before {
  content: "";
}

.icon-soundcloud2:before {
  content: "";
}

.icon-soundcloud22:before {
  content: "";
}

.icon-skype2:before {
  content: "";
}

.icon-reddit:before {
  content: "";
}

.icon-hackernews:before {
  content: "";
}

.icon-wikipedia:before {
  content: "";
}

.icon-linkedin3:before {
  content: "";
}

.icon-linkedin22:before {
  content: "";
}

.icon-lastfm2:before {
  content: "";
}

.icon-lastfm22:before {
  content: "";
}

.icon-delicious:before {
  content: "";
}

.icon-stumbleupon2:before {
  content: "";
}

.icon-stumbleupon22:before {
  content: "";
}

.icon-stackoverflow:before {
  content: "";
}

.icon-pinterest2:before {
  content: "";
}

.icon-pinterest22:before {
  content: "";
}

.icon-xing2:before {
  content: "";
}

.icon-xing22:before {
  content: "";
}

.icon-flattr2:before {
  content: "";
}

.icon-foursquare2:before {
  content: "";
}

.icon-yelp2:before {
  content: "";
}

.icon-paypal2:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-firefox:before {
  content: "";
}

.icon-IE:before {
  content: "";
}

.icon-edge:before {
  content: "";
}

.icon-safari:before {
  content: "";
}

.icon-opera:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-file-openoffice:before {
  content: "";
}

.icon-file-word:before {
  content: "";
}

.icon-file-excel:before {
  content: "";
}

.icon-libreoffice:before {
  content: "";
}

.icon-html-five:before {
  content: "";
}

.icon-html-five2:before {
  content: "";
}

.icon-css3:before {
  content: "";
}

.icon-git:before {
  content: "";
}

.icon-codepen:before {
  content: "";
}

.icon-svg:before {
  content: "";
}

.icon-IcoMoon:before {
  content: "";
}
/*# sourceMappingURL=index.f9226973.css.map */
